.CateTable-wrapper {
  .ant-table-wrapper.CateTableTable {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          .ant-table-tbody {
            .ant-table-row:hover {
              .ant-table-cell {
                background-color: #ffffff !important;
              }
            }
          }
        }
      }
    }
  }
  // .ant-table-row-level-0 {
  //   height: 57px;
  // }
  .ant-table-wrapper {
    // max-width: 75% !important;
  }
  .ant-table-thead {
    font-size: 12px !important;
  }
  .CateTable-best_seller {
    .ant-table-tbody {
      height: 790px !important;
    }
  }
  .CateTable-brand_ranking {
    .ant-table-body {
      .ant-table-tbody {
        height: 790px !important;
      }
    }
  }
  .CateTable-supplier_ranking {
    .ant-table-tbody {
      height: 790px !important;
    }
  }
  .CateTable-sub_cate_sale {
    .ant-table-tbody {
      height: 790px !important;
    }
  }
  .CateTable-product_sale_by_sale_channel {
    .ant-table-tbody {
      height: 790px !important;
    }
  }
  .CateTable-product_sale_by_delivery_method {
    .ant-table-tbody {
      height: 790px !important;
    }
  }
  .CateTable-subcate_sale_by_sale_channel {
    .ant-table-tbody {
      height: 790px !important;
    }
  }
  .CateTable-subcate_sale_by_delivery_method {
    .ant-table-tbody {
      height: 790px !important;
    }
  }
  .ant-tree {
    .ant-tree-treenode {
      padding: var(--sp_lv_4);
      width: 100%;
      border-top: 1px solid var(--divider);

      &:first-child {
        border-top: none;
      }

      &-selected {
        background-color: var(--brand_light);
      }

      .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: var(--brand_light);
      }
    }
    .ant-tree-list {
      .ant-tree-list-holder {
        height: 795px;
        overflow: auto;
      }
    }
  }
}

.CateTable-grid-container {
  height: 790px !important;
  .Products-grid-title {
    height: 50px;
    background-color: var(--table_header);
    color: var(--gray_7);
    font-weight: var(--fw_semibold);
    font-size: var(--fs_sm);
    padding-left: var(--sp_lv_4);
    line-height: 50px;
    position: sticky;
    top: 0;
    z-index: 1000;
    border-left: 1px solid var(--divider);
    border-bottom: 1px solid var(--divider);
    border-right: 1px solid var(--divider);
    border-radius: 0px var(--sp_lv_1) 0px 0px;
  }
  .Products-grid {
    padding: var(--sp_lv_8) 0px;
    grid-template-columns: repeat(auto-fit, 450px);
    justify-content: center;
    height: 858px !important;
    background-color: #f5f5f5;
  }
}
